<div class="facet-filter d-block mb-3 p-3">
  <div (click)="toggle()" class="filter-name">
    <h5 class="d-inline-block mb-0">
      {{ label | translate }}
    </h5>
    <span class="filter-toggle fas float-right"
          [ngClass]="(collapsed$ | async) ? 'fa-plus' : 'fa-minus'">
    </span>
  </div>
  <div [@slide]="(collapsed$ | async) ? 'collapsed' : 'expanded'"
       (@slide.start)="startSlide($event)" (@slide.done)="finishSlide($event)"
       class="sidebar-filter-wrapper" [ngClass]="{'closed' : closed}">
    <div>
      <div class="filters py-2">
        <ng-template *ngIf="!singleValue">
          <ds-sidebar-filter-selected-option
            *ngFor="let value of (selectedValues | async)"
            [label]="value"
            (click)="removeValue.emit(value)">
          </ds-sidebar-filter-selected-option>
        </ng-template>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
