<div class="card">
  <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/communities/', object.id]" class="card-img-top">
    <ds-thumbnail [thumbnail]="(object.logo | async)?.payload" [limitWidth]="false">
    </ds-thumbnail>
  </a>
  <span *ngIf="linkType == linkTypes.None" class="card-img-top">
    <ds-thumbnail [thumbnail]="(object.logo | async)?.payload" [limitWidth]="false">
    </ds-thumbnail>
  </span>
  <div class="card-body">
    <h4 class="card-title">{{object.name}}</h4>
    <p *ngIf="object.shortDescription" class="card-text">{{object.shortDescription}}</p>
    <div *ngIf="linkType != linkTypes.None" class="text-center">
      <a [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/communities/', object.id]" class="lead btn btn-primary viewButton">View</a>
    </div>
  </div>
</div>
